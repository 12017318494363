@import "vars";
@import url(https://fonts.googleapis.com/css?family=Montserrat);
@import url('https://fonts.googleapis.com/css2?family=Braah+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Vina+Sans&display=swap');

.homeLogo {
  margin-top: 4px;

  & .description  {
    margin: 40px 0;

    & h2 {
      font-size: 32px;
      line-height: 140%;
      font-weight: 700;
      text-indent: 30px;
      max-width: 410px;
    }

    & p {
      max-width: 400px;
      font-weight: 500;
      font-size: 18px;
      line-height: 140%;
      margin-top: 12px;
    }

    & .buttons {
      gap: 8px;
      flex-wrap: wrap;
    }

    & .imgs {
      margin-top: 22px;
      justify-content: flex-end;
      img {
        height: fit-content;
      }
    }

    &-text {
      padding-left: 12px;

      @include mobile {
        padding-left: 0;
      }
    }
  }

  & .home-marque {
    font-size: 18px;
    line-height: 41px;
    font-weight: 700;
    position: relative;

    &:before,
    &:after {
      content: "";
      position: absolute;
      width: 100px;
      height: 100%;
      top: 0;
      z-index: 2;

      background: linear-gradient(90deg, #FFFFFF 0%, rgba(255, 255, 255, 0.8) 50%, rgba(255, 255, 255, 0) 100%);
    }

    &:before {
      left: 0;
    }
    &:after {
      right: 0;
      transform: rotate(180deg);
    }

    &--border {
      margin-top: 22px;
      border-bottom: 1px solid #8F8F98;
    }

    & span {
      margin: 0 8px;
    }

    & .box {
      background: #F5F3EF;
      border-radius: 16px;
      padding: 10px 12px;
    }
  }

  @include mobile {
    width: calc(100% + 24px);
    margin-left: -12px;

    & .description  {
      margin: 16px 0;
      padding: 0 12px;

      & h2 {
        font-size: 24px;
        max-width: 325px;
      }

      & p {
        margin: 8px 0;
        font-size: 14px;
      }

      & .imgs {
        margin-top: 0;
        align-items: flex-end;
      }
    }

    & .home-marque {
      margin-left: -16px;
      width: calc(100% + 32px);
      font-size: 16px;
      line-height: 38px;

      &--border {
        margin-top: 10px;
      }
    }
  }
}

.logo {
  width: 100%;
  position: relative;
  overflow: hidden;

  svg {
    width: 100%;
  }

  .text-copy, path {
    fill: none;
    stroke: #000;
    stroke-dasharray: 70% 10%;
    stroke-width: 3px;
    stroke-dashoffset: 0%;
    animation: stroke-offset 40s infinite linear;
  }

  path:nth-child(1){
    animation-delay: -1;
  }

  path:nth-child(2n+1){
    animation-delay: -2s;
  }

  path:nth-child(3n+1){
    animation-delay: -3s;
  }

  path:nth-child(4n+1){
    animation-delay: -4s;
  }

  @keyframes stroke-offset{
    0% {stroke-dashoffset: 0%;}
    50% {stroke-dashoffset: -100%;}
    100% {stroke-dashoffset: 0%;}
  }

  @include mobile() {
    svg {
      height: 56px;
    }
  }
}
