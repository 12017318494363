@import "./vars";


.about {
  & .dualSide {
    gap: 0;

    @include mobile {
      gap: 24px;
    }
  }

  &-desc {
    gap: 6px;

    @include mobile {
      gap: 0;
    }
  }

  .about-title {
    text-indent: 32px;
    margin-top: 18px;
    font-size: 32px;
    line-height: 140%;
    text-transform: none;
    padding-right: 69px;
    font-weight: 700;

    @include mobile {
      font-size: 24px;
      margin-top: 16px;
      padding-right: 0px;
    }
  }

  .about-right {
    display: flex;
    gap: 142px;

    @include mobile {
      flex-direction: column-reverse;
      gap: 24px;
    }
  }

  .about-description {
    font-size: 16px;
    font-weight: 500;
    margin-top: 24px;

    @include mobile {
      margin-top: 24px;
    }
  }

  .eye-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 32px;
    padding-left: 22px;

    .eye {
      width: fit-content;
      position: relative;

      &-center {
        position: absolute;
        display: flex;
        top: 0;
        width: 100%;
        justify-content: space-between;

        & > div {
          width: 40px;
          height: 40px;
          position: relative;
          border-radius: 20px;
          overflow: hidden;

          & > img {
            position: absolute;
            top: 0;
            left: 0;
            transform: translate(-50%, -50%);
          }
        }
      }
    }

    .about-directions {
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      max-width: 75%;
      margin-top: 32px;
      border-collapse: collapse;

      & tr {
        display: flex;
        flex-wrap: wrap;
      }

      & td {
        color: #1c1c1c;
        border: 1px solid #1F1E1E33;
        padding: 4px 16px;
        line-height: 130%;

        &.bt { border-top-width: 0 }
        &.bl { border-left-width: 0 }
        &.bb { border-bottom-width: 0 }
      }

      @include mobile {
        margin-top: 24px;
      }
    }

    @include mobile {
      margin-top: 16px;
      padding-left: 0;
    }
  }
}
