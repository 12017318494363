@import "vars";

.container {
  padding-left: 24px;
  padding-right: 24px;
  max-width: 1440px;

  &.mt-40 {
    margin-top: 40px;
  }

  @include mobile {
    padding-left: 16px;
    padding-right: 16px;
  }
}

.anchor {
  transform: translateY(-150px);
}

.button {
  width: fit-content;
  height: fit-content;
  background: $bg;
  padding: 12px 45px 10px 16px;

  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  text-decoration: unset;
  font-style: italic;

  border: 1px solid $mainDark;
  border-radius: 100px;

  cursor: pointer;
  transition: all .3s ease;
  overflow: hidden;
  position: relative;

  & span {
    color: $mainDark;
    text-transform: uppercase;
    transition: all 300ms ease;
    position: relative;
    z-index: 1;
  }

  &:after {
    content: "";
    mask-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.5354 15.5348L16.2425 14.8277L14.8283 13.4135L16.2425 11.9993L14.8283 10.585L6.34299 19.0703L4.92878 17.6561L13.4141 9.17083L11.9998 7.75661L10.5856 9.17083L9.17142 7.75661L8.46432 8.46372L7.0501 7.04951L9.17142 4.92819L10.5856 6.3424L11.9998 4.92819L13.4141 6.3424L14.8283 4.92819L15.5354 5.63529L16.2425 4.92819L16.9496 5.63529L17.6567 4.92819L19.0709 6.3424L18.3638 7.04951L19.0709 7.75661L18.3638 8.46372L19.0709 9.17083L17.6567 10.585L19.0709 11.9993L17.6567 13.4135L19.0709 14.8277L16.9496 16.949L15.5354 15.5348Z' fill='%231F1E1E'/%3E%3C/svg%3E%0A");
    width: 24px;
    height: 24px;
    position: absolute;
    top: 6px;
    right: 16px;
    background: $mainDark;
    transition: all 300ms ease;
  }

  --gradient: #303030;

  &:before {
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: -100%;
    position: absolute;
    background: var(--gradient);
    transition: all 300ms ease-out;
    border-radius: 40px;
  }

  &:hover,
  &:focus {
    span {
      color: $bg;
    }

    &:after {
      background: $bg;
      transform: translate(2px, -2px);
    }

    &:before {
      left: 0;
    }
  }
}

h1 {
  font-size: 42px;
  line-height: 58px;

  &.smallTitle {
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 16px;
    font-weight: 700;
  }

  &:not(.noUpperCase) {
    text-transform: uppercase;
  }

  @include mobile {
    font-size: 24px;
    line-height: 28px;
  }
}

.bull-icon {
  height: 72px;
  left: 21px;
  position: absolute;
  z-index: 1;

  &.invert {
    filter: invert(1);
  }

  @include mobile {
    height: 40px;
    left: 16px;
  }
}

.levitation-button {
  width: 130px;
  height: 54px;
  right: 24px;
  position: absolute;
  transform: rotate(-8deg);
  @include shadowContainer();
  z-index: 1;
  text-decoration: none;

  & div span {
    transform: rotate(8deg);
  }

  @include mobile {
    width: 120px;
    height: 46px;
    font-size: 12px;
    right: 16px;
  }
}
