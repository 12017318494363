@import "./vars";

.prices-list {
  gap: 22px;

  @include mobile {
    flex-direction: column;
  }

  &--item {
    height: 372px;
    padding: 24px;
    border: 1px solid #8F8F98;
    border-radius: 24px;
    color: #000000;
    flex: 1 1;

    .type {
      font-weight: 700;
      font-size: 32px;
      line-height: 38px;
      margin-bottom: 24px;
    }

    .description {
      font-size: 16px;
      line-height: 140%;
    }

    .groups {
      gap: 8px;
      margin-top: 40px;
      margin-bottom: 24px;

      &--item {
        padding: 8px 16px;
        background: #1F1E1E;
        border: 1px solid #8F8F98;
        border-radius: 16px;
        color: #fff;
        font-weight: 700;
        font-size: 18px;
        white-space: nowrap;
      }
    }

    .price {
      font-size: 24px;
      line-height: 1;

      .value {
        font-size: 47px;
        font-weight: 700;
        margin-left: 17px;
      }
    }

    @include mobile {
      padding: 16px;
      height: 312px;

      .description {
        font-size: 14px;
      }

      .groups--item {
        font-size: 14px;
      }

      .price {
        font-size: 20px;

        .value {
          font-size: 32px;
        }
      }
    }
  }
}
