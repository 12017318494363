@import "vars";

.comingSoon {
  height: 520px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #202030;

  & h1 {
    margin: 16px 0;
  }

  & p {
    font-weight: 500;
    font-size: 18px;
  }

  @include mobile {
    height: 70vh;

    & p {
      font-size: 16px;
    }
  }
}
