@import "vars";

.drawer {
  height: 100%;
  width: 100%;
  top: 0;
  z-index: 2;
  position: fixed;
  transition: background-color 300ms ease;

  &:has(> .isOpen) {
    background: rgba(28, 28, 28, 0.4);
  }

  &-content {
    background: #fff;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;

    height: 100%;
    width: 100%;
    max-width: 1440px;
    top: 100%;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    transition: top .4s ease;

    &.isOpen {
      top: 0;
    }

    &__main {
      height: 100%;
      overflow: scroll;
      padding: 24px 40px;

      .top-helper {
        color: #8B8B8B;
        font-weight: 700;
        font-size: 16px;
        line-height: 140%;
      }

      & h1 {
        font-weight: 700;
        font-size: 100px;
        line-height: 120%;
        text-transform: none;
      }

      .description {
        margin-top: 16px;
        padding: 20px 0;
        border-top: 1px solid $middleGrey;
        border-bottom: 1px solid $middleGrey;

        display: flex;
        justify-content: space-between;

        & > p {
          font-size: 16px;
          max-width: 448px;
          font-weight: 500;
        }

        .realised {
          display: flex;
          justify-content: center;
          gap: 48px;
          width: 50%;

          & ul {
            list-style-position: outside;

            & li {
              font-weight: 500;
              font-size: 16px;
              line-height: 140%;
              color: #000;
            }
          }
        }

        @include mobile {
          flex-direction: column;

          .realised {
            flex-direction: column;
            width: 100%;
            margin-top: 24px;
            margin-left: 24px;
            gap: 24px;
          }
        }
      }

      .content {
        margin-top: 24px;
        display: flex;
        flex-direction: column;
      }

      @include mobile {
        padding: 16px;

        .top-helper {
          font-size: 14px;
        }

        & h1 {
          font-size: 48px;
          margin-bottom: 10px;
        }

        .description > p {
          font-size: 14px;
        }
      }
    }
  }

  &-navigation {
    width: 100%;
    display: flex;
    justify-content: center;

    & > div {
      display: flex;
      flex-direction: column;
      align-items: center;

      &:last-child {
        margin-left: 24px;
      }
    }

    &__button {
      width: 120px;
      height: 120px;
      position: relative;
      margin-top: 20px;

      @include shadowContainer();

      @include mobile {
        zoom: calc(80 / 120);
      }
    }

    & p {
      font-size: 14px;
      line-height: 17px;
      text-transform: uppercase;
      text-align: center;
      margin-top: 20px;
      cursor: default;
      font-style: italic;
    }
  }

  &-close {
    position: absolute;
    right: 24px;
    top: 24px;
    cursor: pointer;

    @include mobile {
      width: 24px;
      height: 24px;
      right: 16px;
      top: 16px;
    }
  }
}
