@import "./vars";

$animation-delay: 300ms;

.reviews-list {
  gap: 22px;

  .actions {
    flex: 1 1;
    padding-right: 24px;

    &-title {
      font-weight: 700;
      font-size: 32px;
      line-height: 140%;
      letter-spacing: 0.02em;
      text-transform: none;
      margin-bottom: 16px;
      white-space: nowrap;
    }

    & > div {
      .button {
        height: fit-content;
      }
    }

    &-arrows {
      gap: 48px;
      margin: 0 -18px 6px 0;
      align-items: flex-end;

      img {
        cursor: pointer;
      }
    }

    @include mobile {
      padding-right: 0;
      margin-bottom: 2px;

      &-title {
        font-size: 24px;
      }

      &-arrows {
        gap: 17px;
        margin: 0;
        align-items: unset;

        img {
          width: 30px;
        }
      }
    }
  }

  .slider {
    gap: 22px;
    overflow: hidden;
    position: relative;
  }

  .pagination {
    margin: -6px auto 0;
    gap: 4px;

    &-item {
      width: 4px;
      height: 4px;
      background-color: #D9D9D9;
      transition: background-color $animation-delay ease;

      &.active {
        background-color: #1F1E1E;
      }
    }
  }

  --offset: 0px;

  &--item {
    flex: 1 1;
    padding: 24px;
    border-radius: 16px;
    border: 1px solid #8F8F98;
    min-width: calc(50% - #{24px / 2 - 1px});
    transition: transform $animation-delay ease;
    transform: translateX(var(--offset));

    @include mobile {
      min-width: 100%;
    }

    .mark-value {
      background: unset;
      font-weight: 700;
      font-size: 32px;
      line-height: 1;
    }

    .mark-stars {
      display: flex;
      gap: 8px;
    }

    .comment {
      margin: 12px 0;
      font-size: 18px;
      line-height: 140%;
      @include textMinMax(106px);
    }

    .author {
      font-size: 14px;
      line-height: 140%;

      .position {
        font-weight: 700;
      }
    }
  }
}
