@import "vars";

.directionsSlider {
  --height: 88px;
  border: 1px solid #000;
  border-radius: 24px;
  overflow: hidden;
  isolation: isolate;

  @include mobile {
    --height: 64px;
    border-radius: 20px;
  }

  height: var(--height);

  &-item {
    height: var(--height);
    padding: 3px 8px;

    @include mobile {
      padding: 0 8px;
    }

    .content {
      width: 100%;
      height: calc(100% - 2px);
      display: flex;
      align-items: center;
      justify-content: center;
      background: red;
      padding-right: 24px;
      background: $mainDark;
      color: #fff;
      border-radius: 16px;
      border: 1px solid $mainDark;

      .icon {
        width: 120px;
        height: 100%;
        border-radius: 14px;
        position: relative;
        overflow: hidden;

        img {
          @include absoluteCenter;
        }
      }

      @include mobile {
        border-radius: 14px;
      }
    }

    & p {
      margin-left: 8px;
      font-weight: 700;
      font-size: 24px;
      line-height: 28px;
      text-transform: uppercase;

      @include mobile {
        font-size: 18px;
        line-height: 20px;
      }
    }
  }

  &-swiper {
    display: flex;

    & > .swiper-wrapper {
      transition-timing-function: linear;
    }

    & .swiper-slide {
      width: fit-content;
    }
  }
}
