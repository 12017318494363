@import "vars";

.contacts {
  & > div {
    gap: 8px;

    @include mobile {
      gap: 0;
    }
  }

  &-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 22px 16px 0;

    & h1 {
      font-weight: 700;
      font-size: 32px;
      line-height: 140%;
      letter-spacing: 0.02em;
      text-transform: none;

      @include mobile {
        font-size: 24px;
      }
    }
  }

  .address_phone {
    display: flex;
    flex-direction: column;

    .address, .phone {
      font-weight: 500;
      font-size: 18px;
      line-height: 140%;
      color: $mainDark;
    }

    .address {
      opacity: 0.5;
    }

    .phone {
      display: inline-block;
      text-decoration: none;
    }
  }

  .socials {
    display: flex;
    gap: 8px ;
    flex-wrap: wrap;
    margin-top: 42px;

    @include mobile {
      order: 2;
      margin-top: 16px;
    }
  }

  .welcome {
    display: flex;
    flex-direction: column;

    & .email {
      font-weight: 500;
      font-size: 32px;
      line-height: 38px;
      text-decoration: none;
      color: $mainDark;
    }

    img {
      margin-top: 20px;
      width: 76px;
    }

    @include mobile {
      flex-direction: row;
      justify-content: space-between;
      margin-top: 16px;
      order: 3;

      & .email {
        font-size: 20px;
        line-height: 23px;
      }

      img {
        margin-top: 16px;
      }
    }
  }

  &-form {
    background: $mainDark;
    border-radius: 24px;
    padding: 24px;

    .form-content {
      position: unset;
      transform: unset;
      width: 100%;

      h1 {
        text-align: left;
        font-weight: 700;
        font-size: 32px;
        line-height: 1;
      }

      .form-group {
        margin-top: 51px;
      }
      .form > div:nth-child(1) .form-group {
        margin-top: 35px;
      }

      & .form-bottom {
        margin-top: 24px;
      }
    }

    @include mobile {
      padding: 16px;
      margin-left: -8px;
      width: calc(100% + 16px);

      .form-content.isOpen {
        margin: 0;
        padding: 4px 0 0 0;

        .form-group,
        .form > div:nth-child(1) .form-group {
          margin-top: 32px;
        }

        .form-bottom {
          align-items: flex-start;
        }

        .form-button {
          margin-right: 0;
          width: 200px !important;
          align-self: baseline;
          margin-top: 16px;

          &:after {
            width: 64px;
          }
        }
      }
    }
  }

  a {
    width: fit-content;
  }

  .dualSide {
    margin-top: 24px;

    @include mobile {
      .address_phone {
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;

        .address, .phone {
          font-size: 14px;
        }
      }

      .socials {
        margin-top: 24px;
      }
    }
  }
}
