@import "./vars";

.projects-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: 32px;
  margin-top: 8px;

  .projectCard {
    width: 448px;
    position: relative;

    &__img {
      border-radius: 24px;
      cursor: pointer;
      overflow: hidden;
      transition: all .3s ease;
      isolation: isolate;
      position: relative;

      & > img {
        transform: scale(1.01);
        transition: transform .4s ease;
      }
    }

    &__desc {
      padding: 16px 0;
      border-bottom: 1px solid #8F8F98;
    }

    &__title {
      margin: 0;
      font-weight: 700;

      font-size: 24px;
      line-height: 29px;
    }

    &__detail {
      width: fit-content;

      font-size: 14px;
      line-height: 16px;
      text-transform: uppercase;
      font-style: italic;
      font-weight: 500;

      border-bottom: 1px dashed #fff;
      cursor: pointer;
      position: relative;
      margin-left: 26px;
      transition: all .2s ease;

      &:before {
        content: url("data:image/svg+xml,%3Csvg width='18' height='14' viewBox='0 0 18 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9 12H10V10H12V8H0V6H12V4H10V2H9V0H12V2H14V4H16V5H17V6H18V8H17V9H16V10H14V12H12V14H9V12Z' fill='black'/%3E%3C/svg%3E%0A");
        position: absolute;
        left: -26px;
        transition: right 200ms ease;
      }

      &:hover {
        opacity: .8;
        border-bottom-color: #000;

        &:before {
          left: -24px;
        }
      }
    }

    &:not(.disabled):hover .projectCard__img {
      border-radius: 56px;

      & > img {
        transform: scale(1.1);
      }
    }

    &.disabled > * {
      //opacity: 0.1;
    }

    &.disabled:before {
      content: "Сoming soon";
      width: 100%;
      height: 100%;
      z-index: 2;
      position: absolute;

      display: flex;
      justify-content: center;
      padding-top: 202px;

      font-weight: 700;
      font-size: 32px;
      line-height: 140%;
      letter-spacing: 0.02em;
      text-transform: uppercase;
      color: #202030;

      @include mobile {
        padding-top: 170px;
      }
    }

    @include mobile {
      width: 100%;
    }
  }
}

.project-tags {
  display: flex;
  gap: 8px;

  &--item {
    padding: 8px 12px;
    border: 1px solid #BEBEBE;
    border-radius: 16px;

    height: 32px;

    font-size: 14px;
    line-height: 16px;
  }
}
