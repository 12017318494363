@import "../vars";

header {
  position: sticky;
  top: 0;
  z-index: 5;
  padding-top: 20px;

  @include mobile {
    padding-top: 4px;
  }

  .header {
    height: 72px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: 0 auto;

    @include mobile {
      height: 56px;
    }

    .header-content {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      padding-left: 120px;

      @include mobile {
        height: 32px;
        background: white;
        padding-left: 0;
        justify-content: center;
        position: fixed;
        bottom: 0;
      }
    }

    & ul {
      display: flex;
      list-style: none;

      li {
        cursor: pointer;
        transition: 500ms;
        padding: 0 2px;
        background: $bg;

        & a {
          color: $mainDark;
          font-size: 14px;
          letter-spacing: 0.05em;
          text-decoration: none;
          font-style: italic;
          font-weight: 500;
        }

        &:not(:first-child) {
          margin-left: 24px;
        }

        &:hover,
        &:focus {
          box-shadow: inset 0 -2.5em 0 0 $mainDark;

          a {
            color: #fff;
          }
        }
      }
    }

    .mobileMenu-btn {
      right: 16px;
      height: 32px;
      width: 32px;
      background: url("../../assets/mobileMenu.svg");
      position: absolute;

      // todo
      display: none !important;
    }
  }
}
