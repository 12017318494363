$mb: 768px;

@mixin mobile($width: $mb) {
  @media screen and (max-width: $width) {
    @content;
  }
}

$bg: #fff;
$mainDark: #1F1E1E;
$middleGrey: #8F8F98;
$error: #C13064;

@mixin shadowContainer() {
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;

  --bg: #fff;
  --border: #1F1E1E;
  --color: #1F1E1E;

  &.pressed {
    --bg: #1F1E1E;
    --border: #fff;
    --color: #fff;
  }

  & div {
    z-index: 1;
    transition: all .2s ease;

    & span {
      color: var(--color);
      display: block;
      font-size: 14px;
      font-style: italic;
      transition: color .2s ease;
    }
  }

  &:before,
  &:after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    border-radius: 50%;
    transition: all .2s ease;
  }

  &:before {
    background: var(--bg);
    border: 1px solid var(--border);
    top: 0;
    z-index: 1;
  }

  &:after {
    background: var(--border);
    bottom: -5px;
  }

  &:hover div {
    margin-top: 8px;
  }
  &:hover:before {
    top: 5px;
  }

  &:active div {
    margin-top: 14px;
  }
  &:active:before {
    top: 6px;
  }
}

@mixin absoluteCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin center($transform) {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) $transform;
}

@mixin textMinMax($height: 100px) {
  min-height: $height;
  max-height: $height;
  overflow: hidden;
  text-overflow: ellipsis;
}
