@import "vars";

.projectsPreview {
  height: 507px;
  margin-top: 6px;

  @include mobile {
    height: unset;
    width: calc(100% + 24px);
    margin-left: -12px;
  }

  &--content {
    &:nth-child(1) {
      padding-right: 16px;
    }

    @include mobile {
      &:nth-child(1) {
        padding-right: 0;
      }
    }
  }

  &--description {
    display: flex;
    flex-direction: column;

    & h2 {
      font-size: 32px;
      line-height: 140%;
      font-weight: 700;
      text-indent: 30px;
      position: relative;
      color: #202030;

      img {
        position: absolute;
        transform: translateY(-40px);
      }
    }

    & p {
      font-size: 18px;
      line-height: 140%;
      margin-top: 16px;
      font-weight: 500;
      max-width: 400px;
    }

    @include mobile {
      margin-top: 50px;
      padding: 0 12px;

      & h4 {
        font-size: 23px;
        line-height: 24px;
      }

      & p {
        font-size: 14px;
        margin-top: 8px;
      }
    }
  }

  .description {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .slider {
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .swiper {
      height: 100%;
      width: 100%;
      overflow: hidden;

      @include mobile {
        height: 256px;
      }
    }

    &-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-transform: uppercase;

      + a {
        overflow: hidden;
        border-radius: 24px;
        transition: all .3s ease;
        isolation: isolate;
        display: block;

        &:hover {
          border-radius: 56px;

          .img {
            transform: scale(1.02);
          }
        }
      }
    }

    .img {
      flex-grow: 1;
      background: #cecece;
      height: 448px;
      transition: transform .3s ease;

      @include mobile {
        height: 183px;
        margin-top: 8px;
      }
    }
  }
}
