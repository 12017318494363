@import "../vars";

.footer {
  margin-top: 40px;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 49px;
    background: $mainDark;
    bottom: 0;
  }

  &__bg {
    width: 100%;
    height: 82px;
    position: relative;
    z-index: 1;

    &--text {
      position: absolute;
      bottom: 14px;
      color: #fff;
      width: 100%;
      padding: 0 24px;
      text-align: center;

      &__policy {
        color: #fff;
        font-style: italic;
      }

      @include mobile {
        bottom: 20px;
        padding: 0 16px;
      }
    }

    &--img {
      width: 100%;
      height: 100%;
      animation: animated-bg 900ms infinite;
    }
  }
}

@keyframes animated-bg{
  0%,100%  {background-image: url("../../assets/footer-bg-frames/frame-1.svg");}
  33% {background-image: url("../../assets/footer-bg-frames/frame-2.svg");}
  66% {background-image: url("../../assets/footer-bg-frames/frame-3.svg");}
}
