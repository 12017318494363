
@import "./vars";

.showreel {

  &-content {
    gap: 24px;

    @include mobile {
      flex-direction: column;
      gap: 0;
    }
  }

  .banner {
    flex-grow: 1;
    height: 448px;
    border-radius: 24px;
    overflow: hidden;

    iframe {
      width: 100%;
      height: 100%;
    }

    @include mobile {
      height: 192px;
      width: 100%;
      border-radius: 9px;
    }
  }

  .play {
    width: 460px;
    font-size: 128px;
    line-height: 1;
    font-weight: 200;
    font-style: italic;
    text-transform: uppercase;

    img {
      width: 158px;
      align-self: center;
      cursor: pointer;
    }

    p:last-child {
      align-self: flex-end;
    }

    @include mobile {
      width: 100%;
      font-size: 45px;
      margin-top: 16px;
      align-items: center;

      img {
        width: 72px;
      }

      p:last-child {
        align-self: center;
      }
    }
  }
}
