@import "vars";

.row {
  display: flex;
  @include mobile {
    flex-direction: column;
  }
}

.col {
  flex-direction: column;
}

.items-center {
  align-items: center;
}

.space-between {
  justify-content: space-between;
}

.c-gap-24 {
  column-gap: 24px;
}

.r-gap-32 {
  row-gap: 32px;
}
