@import "header";
@import "footer";
@import "../vars";

main {
  cursor: default;

  max-width: 1440px;
  margin: 0 auto;
  flex-grow: 1;

  @include mobile {
    max-width: 100%;
  }
}

.dualSide {
  display: flex;

  & > div:nth-child(1) {
    width: calc(1440px - #{920px + 48px});
  }
  & > div:nth-child(2) {
    width: 920px;
  }

  .subtitle {
    font-size: 32px;
    line-height: 140%;
    text-indent: 40px;
  }

  &.reverse {
    flex-direction: row-reverse;
  }

  @include mobile {
    flex-direction: column;

    &.reverse {
      flex-direction: column-reverse;
    }

    & > div:nth-child(1) {
      width: 100%;
    }

    & > div:nth-child(2) {
      width: 100%;
    }
  }
}

.mb-show {
  display: none !important;
  @include mobile {
    display: flex !important;
  }
}

.d-show {
  display: flex !important;
  @include mobile {
    display: none !important;
  }
}
