@import "vars";

#form {
  height: 100%;
  width: 100%;
  top: 0;
  z-index: 2;
  position: fixed;

  .form-header {
    position: relative;
    margin: 0 auto;

    .bull-icon {
      top: 20px;

      @include mobile {
        top: 12px;
      }
    }
    .levitation-button {
      top: 32px;

      @include mobile {
        top: 10px;
      }
    }
  }

  .form-bg {
    background: $mainDark;

    width: 150px;
    height: 50px;
    top: 0;
    right: 0;
    position: absolute;
    border-radius: 50%;
    transform: rotate(-8deg);
    transition: all .4s ease;

    &.isOpen {
      height: 200%;
      width: 200%;
      top: -50%;
      right: -50%;
      border-radius: 0;
    }

    @include mobile {
      @include center(rotate(0));

      &.isOpen {
        @include center(rotate(0))
      }
    }
  }
}

.form-content {
  opacity: 0;
  width: 684px;
  transition: all .3s ease;
  text-align: center;
  color: #fff;
  position: relative;

  @include center(rotate(0));

  &.isOpen {
    opacity: 1;
    height: fit-content;
  }

  & h1 {
    text-transform: uppercase;
  }

  & .form {
    display: flex;

    &-button {
      $trans-time: 300ms;
      cursor: pointer;
      height: 48px;
      width: 133px;
      margin-right: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      text-align: right;
      transition: $trans-time opacity ease;

      &:before {
        left: 22px;
        z-index: 1;
        position: absolute;
        transform: translateY(2px);
        content: url("data:image/svg+xml,%3Csvg width='18' height='14' viewBox='0 0 18 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9 12H10V10H12V8H0V6H12V4H10V2H9V0H12V2H14V4H16V5H17V6H18V8H17V9H16V10H14V12H12V14H9V12Z' fill='%23202030'/%3E%3C/svg%3E%0A");
        transition: $trans-time left ease;
      }

      &:after {
        left: 0;
        content: "";
        width: 64px;
        height: 100%;
        background: $bg;
        border-radius: 40px;
        position: absolute;
        transition: $trans-time width ease;
      }

      & > span {
        z-index: 1;
        position: absolute;
        text-transform: uppercase;
        font-style: italic;
        font-weight: 500;
        right: 20px;
        mix-blend-mode: difference;
        user-select: none;
        width: fit-content;
      }

      &:hover,
      &.submitted {
        &:before {
          left: 26px;
        }
        &:after {
          width: 100%;
        }
      }

      &:active,
      &.submitted {
        opacity: 0.5;
      }

      &.reverse {
        width: 204px;

        &:before {
          transform: rotate(180deg) translateY(2px);
        }

        &:hover,
        &.submitted {
          &:before {
            left: 18px;
          }
        }
      }

      @include mobile {
        margin-top: 24px;
        width: 100% !important;
        &:after {
          width: 100%
        }
        & > span {
          @include center(rotate(0))
        }
      }
    }

    &-bottom {
      margin-top: 48px;

      &__rules {
        display: flex;
        align-items: center;
      }

      @include mobile {
        margin-top: 24px;
      }
    }
  }

  & .success {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    min-height: 300px;

    &_content {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      flex-grow: 1;

      & img {
        width: 36px;
        margin-bottom: 40px;
      }
    }
  }

  @include mobile {
    width: 100%;
    overflow: scroll;
    left: 0;
    top: 0;
    transform: none;

    &.isOpen {
      height: calc(100% - 92px);
      margin-top: 92px;
      padding: 0 22px 32px;
      display: flex;
      flex-direction: column;
    }
  }
}

// INPUTS // ============================== //
$bg-color: $middleGrey;
$hl-color: #fff;
$muted-color: mix(white, $bg-color, 70%);
$trans-time: 300ms;

.form-group {
  position: relative;
  margin: 60px 0 0;
  width: 100%;

  @include mobile {
    margin: 40px 0 0;
  }
}

textarea {
  resize: none;
}

input[type="text"],
input[type="tel"],
input[type="email"],
input[type="password"] {
  background: none;
  color: $muted-color;
  font-size: 18px;
  line-height: 100%;
  padding: 10px 10px 10px 5px;
  display: block;
  width: 100%;
  border: none;
  border-radius: 0;
  outline: none;
  position: relative;
  z-index: 2;

  & ~ label {
    color: $hl-color;
    font-size: 12px;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 5px;
    top: -14px;
    transition: $trans-time ease all;
    line-height: 16px;
  }

  & ~ .bar {
    position: relative;
    display: block;
    width: 100%;
    height: 1px;
    background: $middleGrey;
    &:before {
      content: '';
      height: 1px;
      width: 0;
      bottom: 0;
      position: absolute;
      background: $bg;
      transition: $trans-time ease all;
      left: 0;
    }
  }

  &::placeholder {
    color: $middleGrey;
    transition: $trans-time ease color;
  }

  &:focus ~ .bar:before {
    width: 100%;
  }

  &[type="password"] {
    letter-spacing: 0.3em;
  }

  &.error {
    border-color: $error;
    & ~ label {
      color: $error;
    }
    & ~ .bar, & ~ .bar:before {
      background: $error;;
    }
  }
}

textarea {
  background: none;
  color: $hl-color;
  font-size: 18px;
  padding: 5px 10px 10px 10px;
  display: block;
  width: 100%;
  border-radius: 8px;
  border: 1px solid $middleGrey;
  transition: $trans-time ease all;
  position: relative;
  z-index: 2;

  & ~ label {
    color: $hl-color;
    font-size: 12px;
    line-height: 16px;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 5px;
    top: -24px;
    transition: $trans-time ease all;
    text-transform: uppercase;
  }

  &:focus {
    outline: none;
    border-color: $hl-color;
  }

  &.error {
    border-color: $error;
    & ~ label {
      color: $error;
    }
  }
}


// CHECKBOX // ============================== //
input[type="checkbox"] {
  display: none;

  & ~ span {
    color: #f3f3f3;
    font-size: 12px;
    font-style: italic;
    line-height: 13px;
    text-align: left;

    & > a {
      color: inherit;
    }
    & > .underline {
      cursor: pointer;
      text-decoration: underline;
    }

    @include mobile {
      font-size: 11px;
    }
  }

  & + label {
    display: inline-block;
    width: 32px;
    height: 32px;
    flex-shrink: 0;
    flex-grow: 0;
    border: 1px solid $middleGrey;
    border-radius: 50%;
    margin-right: 10px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
    transition: $trans-time ease all;
    position: relative;
    cursor: pointer;
  }

  & + label:before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-radius: 50%;
    background-color: $bg;
    transition: $trans-time ease all;
    @include center(rotate(0))
  }

  &:not(:disabled):not(:checked) + label:hover {
    border-color: $bg;
  }
  &:checked + label {
    border-color: $bg;
    background-color: $mainDark;
  }
  &:checked + label:before {
    width: 12px;
    height: 12px;
  }

  &:disabled + label:before {
    background-color: $middleGrey;
  }

  &.error + label {
    border-color: $error;
  }
}
